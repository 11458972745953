<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-bell</v-icon> Noticias y avisos</h1><br>
        <v-layout>
          <v-flex xs12>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                  <h2>Avisos urgentes</h2>
                  <template v-slot:actions>
                    <v-icon color="yellow">
                      mdi-alert
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-timeline dense>
                    <v-slide-x-reverse-transition
                      group
                      hide-on-leave
                    >
                      <v-timeline-item
                        v-for="item in avisos"
                        :key="item.id"
                        :color="item.color"
                        small
                        fill-dot
                      >
                        <v-alert
                          :value="true"
                          :color="item.color"
                          :icon="item.icon"
                          class="white--text"
                        >
                          {{item.text}}
                          <div style="float: right">
                            <v-icon v-if="item.color=='red'" style="margin-left: 1em" small @click="marcarLeido(item)">
                              mdi-check
                            </v-icon>
                            <v-icon style="margin-left: 1em" small @click="eliminarAviso(item)">
                              mdi-delete
                            </v-icon>
                          </div>
                        </v-alert>
                      </v-timeline-item>
                    </v-slide-x-reverse-transition>
                  </v-timeline>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                  <h2>Noticias</h2>
                  <template v-slot:actions>
                    <v-icon color="blue">
                      mdi-information
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="ListCard">
                    <v-row>
                      <v-col class="col-md-3 col-xs-12" v-for="i in noticias" :key="i.id">
                        <v-card @click="openNotice(i)">
                          <v-img
                            class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            :src="i.imagen"
                          >
                            <v-card-title>{{ i.titulo }}</v-card-title>
                          </v-img>

                          <v-card-text>
                            <div>{{ i.resumen }}</div>
                          </v-card-text>

                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <showMessage ref="snackbar" />
    <showDialog ref="dialog" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import genericReload from '../components/genericReload'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
const ICONS = {
  read: 'mdi-check',
  notRead: 'mdi-alert-decagram',
}
export default {
  name: 'Noticias',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      panel: 0,
      noticias: [],
      avisos: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.readAvisos();
    this.readNoticias();
  },
  methods:{
    async readAvisos(){
      this.avisos = [];
      const response = await genericReload(this.$store.state.auth, `aviso`);
      if (response.status === 200) {
        const json = await response.json();
        for (let i = 0; i < json.body.length; i++) {
          if(json.body[i].leido){
            this.avisos.push({id: json.body[i].id, text: json.body[i].mensaje, color: "green", icon: ICONS.read});
          }else{
            this.avisos.push({id: json.body[i].id, text: json.body[i].mensaje, color: "red", icon: ICONS.notRead});
          }
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos", "Cerrar", "red");
      }
    },
    async marcarLeido(item){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      const response = await fetch(
        `${BASE_URL}/aviso/${item.id}`,
        {
          method: 'PUT',
          headers: headers,
        }
      );
      switch (response.status) {
        case 200:
          item.color = "green";
          item.icon = ICONS.read;
          this.$refs.snackbar.setNew(true, "Aviso marcado como leído", "Ok", "green");
          break;
        default:
          this.$refs.snackbar.setNew(true, "ERROR. No se ha podido modificar el estado del aviso", "Cerrar", "red");
          this.loading = false;
          break;
      }
    },
    async eliminarAviso(item){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      const response = await fetch(
        `${BASE_URL}/aviso/${item.id}`,
        {
          method: 'DELETE',
          headers: headers,
        }
      );
      switch (response.status) {
        case 200:
          this.$refs.snackbar.setNew(true, "Aviso eliminado correctamente", "Ok", "green");
          this.readAvisos();
          break;
        default:
          this.$refs.snackbar.setNew(true, "ERROR. No se ha podido eliminar el aviso", "Cerrar", "red");
          this.loading = false;
          break;
      }
    },
    previewImgNoticia(){
      if(this.imgNoticia){
        this.url = URL.createObjectURL(this.imgNoticia);
      }else{
        this.url = null;
      }
    },
    async getImg(id){
      const response = await genericReload(this.$store.state.auth, `noticia/${id}/imagen`);
      if (response.status === 200) {
        const blob = await response.blob()
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(blob);
        return imageUrl;
      }
    },
    async readNoticias(){
      this.noticias = [];
      const response = await genericReload(this.$store.state.auth, `noticia`);
      if (response.status === 200) {
        const json = await response.json();
        this.noticias = json.body;
        for (let i = 0; i < this.noticias.length; i++) {
          this.noticias[i].imagen = await this.getImg(this.noticias[i].id);
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos", "Cerrar", "red");
      }
    },
    openNotice(i){
      this.$refs.dialog.setNew("", "", "notice", i).then(async(confirm) => {});
    }
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
hr{
  margin: 3% 0;
  color: #91dc5a;
}
.ListCard .v-card{
  text-align:left;
  transition: transform .2s;
}
.ListCard .v-card:hover {
  transform: scale(1.05);
  z-index: 2;
}
.ListCard .v-card__subtitle {
  font-weight: bold;
}
.ListCard .v-chip {
  float:right;
}
</style>