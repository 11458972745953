<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-alert</v-icon> Alertas de precios</h1><br>
        <v-layout>
          <v-flex xs3>
            <v-select
              label="Producto"
              v-model="producto"
              outlined
              :items="productos"
              item-text="n"
              item-value="v"
              @change="loadCategory(); getCampanas();"
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-select
              label="Calidad / categoría"
              outlined
              v-model="calidad"
              :items="calidades"
              item-text="nombre"
              item-value="nombre"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-select
              label="Campaña"
              outlined
              v-model="campana"
              :items="campanas"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Precio mínimo"
              v-model="precioMin"
              type="number"
              min="0"
              step="0.5"
              outlined
              @change="checkMinPrice()"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Precio máximo"
              v-model="precioMax"
              type="number"
              min="0"
              step="0.5"
              outlined
              @change="checkMaxPrice()"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" @click="save"><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="resumen"
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="elimina(item.id)">
            mdi-delete
          </v-icon>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Precios',
  data () {
    return {
      precioMax: '',
      precioMin: '',
      producto: '',
      productos: [{n: 'Aceituna', v: 'Aceituna'},{n: 'Frutos secos', v: 'Cascara'},{n: 'Frutales', v: 'Frutales'}],
      calidades: [],
      calidad: null,
      campana: null,
      campanas: [],
      headersResumen: [
        { text: 'Producto', value: 'producto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Campaña', value: 'campana' },
        { text: '€/Kg Min', value: 'precioMin' },
        { text: '€/Kg Max', value: 'precioMax' },
        { text: "", value: "actions" },
      ],
      resumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.getCampanas();
    this.read();
  },
  methods:{
    async getCampanas(){
      this.campanas = [];
      const response = await genericReload(this.$store.state.auth, `entrada/?tipo=${this.producto}`);
      let arrayCampanas = []
      if (response.status === 200) {
        const json = await response.json();
        if(json.body.length > 0){
          for(let i = 0; i<json.body.length; i++){
            arrayCampanas.push(json.body[i].fecha);
          }
          arrayCampanas.sort(function(a,b){ return new Date(a) - new Date(b); });
          const anoInicio = parseInt(arrayCampanas[0].split("-")[0]);
          const anoFin = parseInt((new Date).getFullYear());
          const numCampanas= anoFin - anoInicio + 1;
          let i = 0;
          while(i<=numCampanas){
            this.campanas.push((anoInicio-1+i)+"/"+(anoInicio+i));
            i++;
          }
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las campañas en las que dispones de entradas de producto", "Cerrar", "red");
      }
    },
    clear(){
      this.precioMax = '';
      this.precioMin = '';
      this.producto = '';
      this.calidades = [];
      this.calidad = null;
      this.campana = null;
      this.campanas = [];
    },
    async loadCategory(){
      this.calidades = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.calidades = JSON.parse(JSON.stringify(json.body.filter((product) => product.producto === this.producto)));
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las calidades / categorías para el producto seleccionado", "Cerrar", "red");
      }
    },
    async save(){
      if(this.producto!='' && this.calidad!=null && this.campana!=null && this.precioMax!='' && this.precioMin!=''){
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+this.$store.state.auth
        };
       const data = {
          producto: this.producto,
          calidad: this.calidad,
          campana: this.campana,
          precioMax: this.precioMax,
          precioMin: this.precioMin
        };
        const response = await fetch(
          `${BASE_URL}/alertasPrecio`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        switch (response.status) {
          case 200:
            this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
            this.clear();
            this.read();
            break;
          default:
            this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados. No se ha podido crear la petición.", "Cerrar", "red");
            break;
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. Debe de completar todos los campos antes de hacer el envío", "Cerrar", "red");
      }
    },
    async read(){
      this.resumen = [];
      const response = await genericReload(this.$store.state.auth, `alertasPrecio`);
      if (response.status === 200) {
        const json = await response.json();
        for(let i = 0; i<json.body.length; i++){
          this.resumen.push({
            id: json.body[i].id, 
            producto: json.body[i].producto,
            calidad: json.body[i].calidad,
            campana: json.body[i].campana,
            precioMin: json.body[i].precioMin,
            precioMax: json.body[i].precioMax,
          });
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de registros anteriores", "Cerrar", "red");
      }
    },
    elimina(id){
      this.$refs.dialog.setNew("Eliminar alerta", "¿Estás seguro de querer llevar a cabo esta operación?", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };
          const response = await fetch(
            `${BASE_URL}/alertasPrecio/${id}`,
            {
              method: 'DELETE',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Registro eliminado correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. El registro no ha podido ser eliminado", "Cerrar", "red");
              break;
          }
        }
      });
    },
    checkMaxPrice(){
      if((parseFloat(this.precioMax) < parseFloat(this.precioMin)) && this.precioMin != ""){
        this.precioMin = parseFloat(this.precioMax)-1;
      }
    },
    checkMinPrice(){
      if((parseFloat(this.precioMin) > parseFloat(this.precioMax)) && this.precioMax != ""){
        this.precioMax = parseFloat(this.precioMin)+1;
      }
    }
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>